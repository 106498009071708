import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@viewer/core/state/store";
import { action, computed, makeObservable, observable } from "mobx";
import { getFullChangeStr } from "@viewer/shared-module/helper.utils";
import { TranslateService } from "@ngx-translate/core";
import { formatCSN } from "@orion2/utils/functions.utils";
import { IpcDetail, AdditionalInformations, EPN } from "@orion2/models/ipc.models";
import xpath from "xpath";
import { isNoNumber } from "@orion2/utils/front.utils";

export interface IpcTocData {
  key: string;
  indenture: number;
  hotspot: string;
  version: IpcDetail[];
  children: IpcTocData[];
  attachingParts: IpcTocData[];
  fittingVariants: IpcTocData[];
}

export interface ISN {
  isnValue?: string;
  label?: string;
  qty?: string;
  totalqty?: string;
  airbusPN?: string;
  equivalentPartNb?: string;
  mpn?: string;
  mfc?: string;
  can?: string;
  replaces?: string;
  replacedBy?: string;
  effectivity?: string[];
  rfd?: string;
  referredBy?: { label: string; link: string };
  referTo?: { label: string; link: string };
  nsn?: string;
  localManufact?: string;
  stock?: string;
  descriptionLocation?: string;
  applicability?: string;
  additionalInformations?: AdditionalInformations;
  availableConfIds?: string[];
  isnId?: string;
  isProcurable: boolean;
}

@Injectable()
export class IpcService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  catalog: any[];
  k = 0;
  public items: IpcTocData[] = [];
  ipcDetailState = false;
  isn = {
    label: "",
    qty: "",
    totalqty: "",
    airbusPN: "",
    equivalentPartNb: "",
    mpn: "",
    mfc: "",
    can: "",
    replaces: "",
    replacedBy: "",
    effectivity: [],
    rfd: "",
    referTo: {
      label: "",
      link: ""
    },
    nsn: "",
    localManufact: "",
    stock: "",
    descriptionLocation: "",
    applicability: ""
  } as ISN;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public ipcXmlObs: BehaviorSubject<any> = new BehaviorSubject(undefined);
  /**
   * TAB for shopping cart
   */
  myBasket = [];
  /**
   * MAP for shopping cart
   */
  myBasketMap = new Map();

  // TAG S100D
  isS1000D = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _selectedParts: BehaviorSubject<any> = new BehaviorSubject({});
  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
  public readonly selectedParts: Observable<any> = this._selectedParts.asObservable();
  private catalogItems: Element[];
  private parentForIndenture = [];
  private parentForAttachingPart: IpcTocData;
  private parentForFittingPart: IpcTocData;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private translate: TranslateService
  ) {
    makeObservable(this, {
      myBasketMap: observable,
      mapBasketLength: computed,
      handleIPC: action,
      setMediaID: action,
      setSelectedParts: action
    });

    const initialSelectedHotspots = this.route.snapshot.queryParamMap.get("hotspotId");
    this.setSelectedParts(initialSelectedHotspots);
  }

  get mapBasketLength() {
    return Array.from(this.myBasketMap).length;
  }

  setCatalog(catalog = []) {
    this.catalog = catalog;
  }

  setSelectedParts(selectedParts: string) {
    this.store.selectedParts = selectedParts || "";
  }

  /**
   * Get a specific CSN details
   *
   * @param item
   * @memberof IpcService
   */
  public getDetailsFromCSN(item: string): IpcDetail {
    if (!this.store.isS1000D) {
      const node = this.getCSNFromHotspotId(item);
      return this.getDetails(node);
    }
    // select catalog with id
    // SPEC
    // the version of the item is the last alphabetic character (example item = 010A)
    // for basic version (example item = 010), version is '0' on S1000D standard
    const version = item.match(/\D/g) ? item.match(/\D/g)[0] : "0";
    const partNode = this.getS1000DPartElement(item);
    const csnNode = this.getS1000DVersionElement(partNode, version);
    const indenture = partNode.getAttribute("indenture");
    const hotspotId = partNode.getAttribute("item") + (version !== "0" ? version : "");
    const id = partNode.getAttribute("id")
      ? partNode.getAttribute("id").split("csn-")[1]
      : hotspotId;

    // fill catalog details
    const details = this.getDetails1000D(csnNode);
    details.csn = formatCSN(id);
    details.id = id;
    details.indenture = parseInt(indenture, 10);
    details.hotspotId = hotspotId;

    return details;
  }

  /**
   * get CSN details
   *
   * @param i (index)
   * @returns json object
   * @memberof IpcTocComponent
   */
  public getDetails(xmlNode: Element): IpcDetail {
    // Retrieve values through IPC service
    const currentIsn = xmlNode.querySelector("ISN[CHANGE]");

    this.isn = this.getInfosISN(currentIsn);

    let hotspotID: string;
    // If a XREF exists, gets the related hotspotId
    const internalRefTag = xmlNode.querySelector("XREF");
    if (internalRefTag) {
      const internalRefId = internalRefTag.getAttribute("XREFID");
      hotspotID = this.store.ipcXML
        .querySelector(`HOTSPOT[ID=${internalRefId}]`)
        .getAttribute("APSNAME");
    } else {
      hotspotID = xmlNode.getAttribute("ITEM");
    }
    const nil = xmlNode.querySelector("NIL")?.innerHTML || "";

    // get DFL to identify custom parts
    const CBS = xmlNode.querySelector("CBS");
    const DFLtags = CBS?.querySelectorAll("DFL");
    const customPart = Array.from(DFLtags)
      .map(tag => tag.innerHTML)
      .includes("!!! CUSTOMIZED PART !!!");

    // get after amandement
    const afterAmendment = xmlNode.querySelector("CAN")?.innerHTML;

    // get attaching part
    const attachingPart = !!xmlNode.querySelector("ASP");

    const change = getFullChangeStr(currentIsn.getAttribute("CHANGE"));

    const indenture = +xmlNode.getAttribute("IND");
    const csn = xmlNode.getAttribute("CSN");
    const applicableVersion = this.getApplicableVersions();

    const details = {
      id: csn,
      label: this.isn["label"],
      qty: this.isn.qty.length > 0 ? this.isn.qty : "-",
      totalqty: this.isn.totalqty,
      airbusPN: this.isn.airbusPN,
      equivalentPartNb: this.isn.equivalentPartNb,
      mpn: this.isn.mpn,
      mfc: this.isn.mfc,
      can: this.isn.can,
      replaces: this.isn.replaces,
      replacedBy: this.isn.replacedBy,
      effectivity: this.isn.effectivity,
      rfd: this.isn.rfd,
      referTo: this.isn.referTo,
      nsn: this.isn.nsn,
      localManufact: this.isn.localManufact,
      stock: this.isn.stock,
      descriptionLocation: this.isn.descriptionLocation,
      applicableVersion,
      applicability: this.isn.applicability,
      hotspotId: hotspotID,
      idTab: 0,
      isn: [],
      allIsn: xmlNode.querySelectorAll("ISN"),
      change,
      csn,
      indenture,
      nil,
      customPart,
      afterAmendment,
      attachingPart,
      isProcurable: this.isn.isProcurable
    } as IpcDetail;

    for (let j = 0; j < details.allIsn.length; j++) {
      details.isn[j] = {
        id: (details.allIsn[j] as Element).getAttribute("ID"),
        pnr: (details.allIsn[j] as Element).querySelector("PNR").innerHTML || "-"
      };
    }

    return details;
  }

  /**
   * Get applicability data for Legacy
   */
  public _retrieveApplicabilityATA(xmlNode: Element): string {
    const applics = xmlNode.querySelectorAll("MOV");
    const indenture = +xmlNode.getAttribute("IND");
    // Added a space after a comma for better visibility
    const applicability = applics[applics.length - 1]?.getAttribute("MOV")?.replace(/,/g, ", ");
    return this.labelApplicability(indenture, applicability);
  }

  /**
   * SPEC: items whose applicability has a range equal to
          1001-9999 or 0005-9999 or 9004-9999 or 0001-9999 ,
          must refer to the applicability of the parent. Except for the root node (indenture = 1)
     SPEC: not diplsay disclaimer in IPC Card
   * @param indenture Inenture of the node (CSN)
   * @param applicability Applicability (serial numbers ranges)
   * @returns The disclaimer or the applicabilty passed as input
   */
  public labelApplicability(
    indenture: number,
    applicability: string,
    displayDisclaimer = true
  ): string {
    if (applicability?.match(/(1001|0005|9004|0001)-9999/g) && indenture > 1) {
      return displayDisclaimer ? this.translate.instant("applicability.sn.disclaimer") : "";
    }
    return applicability;
  }

  /**
   * Get all ISN datas
   *
   * @param {Element} currentIsn
   * @returns isn object
   * @memberof IpcService
   */
  public getInfosISN(currentIsn: Element): ISN {
    const status = currentIsn.getAttribute("CHANGE");
    // We don't want get infos if it's a deleted ISN due to missing attribute
    if (status === "D") {
      this.isn = {
        label: "DELETED",
        qty: "",
        totalqty: "",
        airbusPN: "",
        equivalentPartNb: "",
        mpn: "",
        mfc: "",
        can: "",
        replaces: "",
        replacedBy: "",
        effectivity: [],
        rfd: "",
        referTo: {
          label: "",
          link: ""
        },
        nsn: "",
        localManufact: "",
        stock: "",
        descriptionLocation: "",
        applicability: "",
        isProcurable: false
      };
      return this.isn;
    }

    const label = currentIsn.querySelector("DFP").innerHTML;
    const qty = currentIsn.querySelector("QNA").innerHTML;
    let totalqty;
    if (currentIsn.querySelector("TQY")) {
      totalqty = currentIsn.querySelector("TQY").innerHTML;
    }
    const rfd = currentIsn.querySelector("RFD") && currentIsn.querySelector("RFD").innerHTML;

    const airbusPN = this.getValues(currentIsn, "ECPNR");

    const equivalentPartNb = this.getValues(currentIsn, "OPN");

    const can = currentIsn.querySelector("CAN") && currentIsn.querySelector("CAN").innerHTML;
    const referNode = currentIsn.querySelector("CSNREF");
    const referTo = {
      label: "",
      link: ""
    };
    if (referNode) {
      referTo.label = referNode.getAttribute("REFCSN");
      referTo.link = referNode.getAttribute("xlink:href");
    }
    const nsn = currentIsn.querySelector("NSN")?.getAttribute("NSN");

    const localManufactDisplayed: string = this.retrieveLocalManufact(currentIsn);
    const stock = currentIsn.querySelector("STOCK")?.getAttribute("STOCK");
    let stockDisplayed: string;
    switch (stock) {
      case "C":
        stockDisplayed = this.translate.instant(
          "ipcDetails.labels.stockAvailableSpecificDeliveryTime"
        );
        break;
      case "NOTAVA":
        stockDisplayed = this.translate.instant("ipcDetails.labels.stockNotAvailable");
        break;
      case "SPECLT":
        stockDisplayed = this.translate.instant("ipcDetails.labels.stockOrderSpecificLeadTime");
        break;
      case "STANREP":
        stockDisplayed = this.translate.instant("ipcDetails.labels.stockStandardExchange");
        break;
      case "STOCK":
        stockDisplayed = this.translate.instant("ipcDetails.labels.stockStock");
        break;
      default:
        stockDisplayed = stock;
        break;
    }
    const alternateParts = Array.from(currentIsn.querySelectorAll("DFL")).map(
      (dfl: Element) => `(${dfl.innerHTML.trim()})`
    );
    let descriptionLocation = alternateParts
      .filter((alternatePart: string) => alternatePart !== "(NP)")
      .join("\n");
    const applicability = this._retrieveApplicabilityATA(currentIsn);
    const effectivity = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ucas: any[] = Array.from(currentIsn.querySelectorAll("UCA"));
    if (ucas) {
      ucas.forEach(effectivityNode => {
        effectivity.push(effectivityNode.innerHTML);
      });
    }
    let replaces;
    if (currentIsn.querySelector("ICYOLD")) {
      replaces = currentIsn.querySelector("ICYOLD").innerHTML;
    }
    let replacedBy;
    if (currentIsn.querySelector("ICYNEW")) {
      replacedBy = currentIsn.querySelector("ICYNEW").innerHTML;
    }
    const mpn = currentIsn.querySelector("PNR").innerHTML;
    const mfc = currentIsn.querySelector("MFC").innerHTML;
    const isProcurable = mpn && !isNoNumber(mpn) && !alternateParts.includes("(NP)");

    if (!isProcurable) {
      descriptionLocation = "(NP)\n" + descriptionLocation;
    }

    this.isn = {
      label,
      qty,
      totalqty,
      airbusPN,
      equivalentPartNb,
      mpn,
      mfc,
      can,
      replaces,
      replacedBy,
      effectivity,
      rfd,
      referTo,
      nsn,
      descriptionLocation,
      localManufact: localManufactDisplayed,
      stock: stockDisplayed,
      applicability,
      isProcurable
    };
    return this.isn;
  }

  /**
   * Construct referential
   *
   * @param ipcDoc
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleIPC(ipcDoc: any) {
    this.items = [];
    this.parentForIndenture = [];
    this.store.hotspotObjects = [];
    this.store.ipcXML = ipcDoc.data;
    this.ipcXmlObs.next(ipcDoc.data);
    if (this.store.isS1000D) {
      this.catalogItems = [].slice.call(this.store.ipcXML.querySelectorAll("catalogSeqNumber"));

      this.store.mediaType =
        this.store.ipcXML.querySelector("multimediaObject") &&
        this.store.ipcXML.querySelector("multimediaObject").getAttribute("multimediaType");
      this.constructIpcTocS1000D(this.catalogItems);
    } else {
      if (this.store.ipcXML.querySelectorAll("CSN").length > 0) {
        this.catalogItems = [].slice.call(this.store.ipcXML.querySelectorAll("CSN"));

        this.constructIpcTocATA(this.catalogItems);
      }
    }
  }

  /**
   * With the xml ATA(Legacy), construct ipc TOC
   * This method reminds itself in a recursive way, this first time, currentNode is empty
   *
   * @param catalogItems is XML Data
   * @param i
   * @param currentNode
   */
  constructIpcTocATA(catalogItems: Element[], i = 0, currentNode?: IpcTocData): void {
    if (i < catalogItems.length) {
      const details = this.getDetails(catalogItems[i]);
      // get unique key for new node
      const key = details.hotspotId;

      // If the CSN does not contain a letter [A-Z] as the last character or it is the first call .
      if (!this.checkVersions(catalogItems, i, key) || !currentNode) {
        currentNode = this.createDataForIpcTocNode(key, details);

        // If the CSN contains a letter, it is checked current CSN and a one of the previous CSN in XML
        // Check if the CSN contain a letter [A-Z] as the last character.
      } else if (i !== 0 && catalogItems[i].getAttribute("CSN").match(/[a-zA-Z]$/g) != null) {
        const currentCatalogItemsSize: number = catalogItems[i].getAttribute("CSN").length;
        const lastCatalogItemsSize: number = catalogItems[i - 1].getAttribute("CSN").length;
        const currentCatalogItems: string = catalogItems[i]
          .getAttribute("CSN")
          .substr(0, currentCatalogItemsSize - 1);
        let lastCatalogItems: string = catalogItems[i - 1].getAttribute("CSN");

        // If exist deletes the letter from CSN -1
        if (catalogItems[i - 1].getAttribute("CSN").match(/[a-zA-Z]$/g) != null) {
          lastCatalogItems = catalogItems[i - 1]
            .getAttribute("CSN")
            .substr(0, lastCatalogItemsSize - 1);
        }

        // If the two CSNs are different then create a new card
        if (currentCatalogItems !== lastCatalogItems) {
          currentNode = this.createDataForIpcTocNode(key, details);
        }
      } else {
        console.error("Fail create Data For Toc Node - " + catalogItems[i].getAttribute("CSN"));
      }

      // Fill node with node details and index for mat tab
      details.idTab = this.k;
      currentNode.version.push(details);
      this.k++;
      // recursively iterates
      this.constructIpcTocATA(catalogItems, i + 1, currentNode);
    }
  }

  /**
   * Compare CSN attribute and Check if versions exist
   *
   * @param i
   * @returns boolean
   * @memberof IpcTocComponent
   */
  checkVersions(catalogItems, i, idNum) {
    if (this.store.isS1000D) {
      return (
        idNum &&
        catalogItems[i] &&
        idNum.match(/[a-zA-Z]$/g) != null &&
        idNum.replace(/\D/g, "") === catalogItems[i].getAttribute("item") &&
        catalogItems[i].querySelectorAll("itemSeqNumber").length > 1
      );
    } else {
      return (
        idNum &&
        catalogItems[i] &&
        idNum === catalogItems[i].getAttribute("ITEM") &&
        idNum.match(/[a-zA-Z]$/g) != null
      );
    }
  }

  /**
   * Update currentMediaID with the picture related to the selected hotspotID
   *
   * @param hotspotId
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  setMediaID(hotspotId: string) {
    let markHotspot: Element;
    let mediaID: string;
    if (this.store.isS1000D) {
      hotspotId = hotspotId.replace(/\D/g, "");
      markHotspot = this.store.ipcXML.querySelector(`catalogSeqNumber[item='${hotspotId}']`);
      if (markHotspot?.querySelector("internalRef")) {
        const internalRefId = markHotspot
          .querySelector("internalRef")
          .getAttribute("internalRefId");
        const infoEntityIdent = this.store.ipcXML.querySelector(`hotspot[id='${internalRefId}']`);
        if (infoEntityIdent) {
          mediaID = infoEntityIdent.parentElement.getAttribute("infoEntityIdent");
        }
      }
      if (!mediaID && !this.store.isPlayerActivated) {
        // SPEC: If the hotpots are not present in the xml and we are in ipc S1000D 2D
        // We get mediaId from store.ipcHotspotMap
        mediaID = this.store.ipcHotspotMap.get(this.store.currentDMC)[hotspotId];

        // SPEC: If no media found in this use case we try again without the 0 padding
        if (!mediaID) {
          mediaID = this.store.ipcHotspotMap.get(this.store.currentDMC)[
            hotspotId.replace(/^0+/g, "")
          ];
        }
      }
    } else {
      markHotspot = this.store.ipcXML.querySelector(`HOTSPOT[APSNAME='${hotspotId}']`);
      if (markHotspot) {
        mediaID = markHotspot.parentElement.getAttribute("BOARDNO");
      }
    }

    if (mediaID) {
      this.store.currentMediaId = mediaID;
    } else {
      // the hotspot is pending to find its mediaId
      // in case of the findAllHotspots function from mediaService
      // has not yet created the ipcHotspotMap
      this.store.pendingHotspot = hotspotId;
    }
  }

  constructIpcTocS1000D(catalogItems: Element[], i = 0, currentNode?: IpcTocData): void {
    if (i < catalogItems.length) {
      const isnList = catalogItems[i].querySelectorAll("itemSeqNumber");
      const indenture = catalogItems[i].getAttribute("indenture");
      const hotspotId = catalogItems[i].getAttribute("item");
      const id = catalogItems[i].getAttribute("id") || hotspotId;
      const csn = formatCSN(id);
      const internalRefId = catalogItems[i]
        .querySelector("internalRef")
        ?.getAttribute("internalRefId");
      const hotspotElementRef =
        internalRefId && this.store.ipcXML.querySelector(`hotspot[id=${internalRefId}]`);
      const hotspotParam =
        hotspotElementRef && hotspotElementRef.getAttribute("applicationStructureIdent");
      this.isn.label = id;
      // We determine if fitting variant or not
      const partStatus: string[] = [];
      isnList.forEach((isn: Element) => {
        partStatus.push(isn.getAttribute("partStatus"));
      });
      const fittingVariant: boolean = partStatus.includes("pst01") && partStatus.includes("pst05");

      for (const isn of Array.from(isnList)) {
        const details = this.getDetails1000D(isn);
        const equivalentPartNb = this.getEquivalentPartNumber(catalogItems[i], details["mpn"]);
        details["equivalentPartNb"] = equivalentPartNb;
        details["id"] = id;
        details["csn"] = csn;
        details["indenture"] = parseInt(indenture, 10);
        details["hotspotId"] =
          details.isnValue[2] === "0" ? hotspotId : hotspotId + details.isnValue[2];
        details["hotspotParam"] = hotspotParam;
        details["fittingVariant"] = fittingVariant;
        const key = details["hotspotId"];

        // SPEC: We want to create a new node only for the first isn version
        if (Array.from(isnList).indexOf(isn) === 0) {
          currentNode = this.createDataForIpcTocNode(key, details);
        }
        if (
          this.k === 0 ||
          (this.k > 0 && currentNode.version[this.k - 1].isnValue !== details.isnValue)
        ) {
          // Fill node with node details and index for mat tab
          // Add version to the current card
          details.idTab = this.k;
          currentNode.version.push(details);
          this.k++;
        } else {
          // If the isn does not have applicability_md5, we fallback to mountable isn (partStatus = pst51)
          const applicabilityMD5 =
            isn.getAttribute("applicability_md5") ||
            Array.from(isnList)
              .find((isnItem: Element) => isnItem.getAttribute("partStatus") === "pst51")
              ?.getAttribute("applicability_md5");
          currentNode.version[this.k - 1].applicabilityMD5 = applicabilityMD5;
        }
        this.store.hotspotObjects.push(details);
      }
      // recursively iterates
      this.constructIpcTocS1000D(catalogItems, i + 1);
    }
  }

  getDetails1000D(xmlNode: Element): IpcDetail {
    const infosISN = this.getInfosISNs1000d(xmlNode);
    this.isn.mpn = infosISN.mpn;
    this.isn.mfc = infosISN.mfc;
    this.isn.qty = infosISN.qty;
    const attachingPart = !!xmlNode.querySelector(
      "partLocationSegment > attachStoreShipPart[attachStoreShipPartCode='1']"
    );
    const nil = xmlNode.querySelector("notIllustrated")?.innerHTML || "";
    const isnId = xmlNode.getAttribute("id");
    //SPEC: we get changeType from ISN tag. If it don't exist, we get it from CSN tag
    const changeType =
      xmlNode.getAttribute("changeType") || xmlNode.parentElement.getAttribute("changeType");
    const change = getFullChangeStr(changeType);
    const applicableVersion = this.getApplicableVersions();
    let availableConfIds = infosISN.availableConfIds;
    if (!availableConfIds && nil === "-") {
      availableConfIds = [isnId];
    }
    return {
      idTab: 0,
      isnValue: infosISN.isnValue,
      mpn: infosISN.mpn,
      mfc: infosISN.mfc,
      qty: infosISN.qty.length > 0 ? infosISN.qty : "-",
      nsn: infosISN.nsn,
      totalqty: infosISN.totalqty,
      label: infosISN.label,
      referredBy: infosISN.referredBy,
      referTo: infosISN.referTo,
      additionalInformations: infosISN.additionalInformations,
      nil,
      attachingPart,
      isnId,
      availableConfIds,
      change,
      applicableVersion,
      isProcurable: infosISN.isProcurable,
      descriptionLocation: infosISN.descriptionLocation
    } as IpcDetail;
  }

  getInfosISNs1000d(currentIsn: Element): ISN {
    // QTY
    const quantityPerNextHigherAssy = currentIsn?.querySelector(
      "quantityPerNextHigherAssy"
    )?.innerHTML;
    // total QTY
    const totalQuantity = currentIsn?.querySelector("totalQuantity")?.innerHTML;
    // Nato Stock number
    const partSegment = currentIsn?.querySelector("partSegment");
    const nsn = partSegment?.querySelector("natoStockNumber")?.innerHTML;
    // Part description label
    const label = partSegment?.querySelector("descrForPart")?.innerHTML;
    // MFC && MPN
    const partRef = currentIsn && currentIsn.querySelector("partRef");
    const manufacturerCodeValue = partRef?.getAttribute("manufacturerCodeValue");
    const partNumberValue = partRef?.getAttribute("partNumberValue");
    const itemSeqNumberValue = currentIsn?.getAttribute("itemSeqNumberValue");

    // Refer to and referred by
    const referredBy = { label: "", link: "" };
    const referTo = { label: "", link: "" };
    const referNodes = currentIsn.querySelectorAll("referTo");
    let availableConfIds: string[];
    referNodes.forEach(referNode => {
      const targetLink = referNode.querySelector("catalogSeqNumberRef")?.getAttribute("xlink:href");
      // SPEC: xlink:href is of shape <DMC>##<csn>, we want to extract the DMC
      // We use a regex to match every characters up to the first "#" encountered
      const targetDmc = /^[^#]*/.exec(targetLink)[0];
      switch (referNode.getAttribute("refType")) {
        case "rft01":
          referredBy.label = this.store.getDUMeta(targetDmc).reference;
          referredBy.link = targetLink;
          break;
        case "rft02":
          referTo.label = this.store.getDUMeta(targetDmc).reference;
          referTo.link = targetLink;
          break;
        case "rft51":
          availableConfIds = Array.from(referNode.querySelectorAll("functionalItemRef")).map(
            (node: Element) => node.getAttribute("functionalItemNumber")
          );
          break;
        default:
          break;
      }
    });

    // Additonnal informations
    const elecItem = [];
    const tabElecItem = currentIsn?.querySelectorAll("itemSeqNumber > functionalItemRef");
    tabElecItem.forEach(item => {
      elecItem.push(item.getAttribute("functionalItemNumber"));
    });
    const smrCode = currentIsn?.querySelector("sourceMaintRecoverability")?.innerHTML || "";
    const unitOfIssue = currentIsn?.querySelector("unitOfIssue")?.innerHTML || "";
    const interchangeability = currentIsn?.querySelector("interchangeability")?.innerHTML || "";
    const descrForLocation = currentIsn?.querySelector("descrForLocation")?.innerHTML || "";
    const attachStoreShipPart =
      currentIsn?.querySelector("attachStoreShipPart")?.getAttribute("attachStoreShipPartCode") ||
      "";

    const additionalInformations: AdditionalInformations = {
      elecItem,
      smrCode,
      unitOfIssue,
      interchangeability,
      descrForLocation,
      attachStoreShipPart
    };

    const isProcurable = smrCode !== "XBZZZ";

    return {
      isnValue: itemSeqNumberValue,
      airbusPN: "",
      can: "",
      descriptionLocation: isProcurable ? "" : "(NP)",
      effectivity: [],
      equivalentPartNb: "",
      localManufact: "",
      mfc: manufacturerCodeValue,
      mpn: partNumberValue,
      qty: quantityPerNextHigherAssy,
      replacedBy: "",
      replaces: "",
      rfd: "",
      stock: "",
      totalqty: totalQuantity,
      additionalInformations,
      nsn,
      label,
      referredBy,
      referTo,
      availableConfIds,
      isProcurable
    };
  }

  getS1000DPartElement(itemId: string) {
    const item = itemId.replace(/\D/g, "");
    return this.store.ipcXML.querySelector(`catalogSeqNumber[item='${item}']`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getS1000DVersionElement(partNode: any, version: any) {
    return partNode.querySelector(`itemSeqNumber[itemSeqNumberValue='00${version}']`);
  }

  /**
   * Get CSN element from the given hotspotId in Legacy
   *
   * @param hotspotId
   */
  getCSNFromHotspotId(hotspotId: string): Element {
    // SPEC : First we try to get the HOTSPOT element from the given hotspotId in the GRAPHIC part of the IPC's XML
    const hotspotEl = this.store.ipcXML.querySelector(`HOTSPOT[APSNAME='${hotspotId}']`);

    // SPEC : If no HOTSPOT is found, the part is not referenced in the figure (ex: the root "1" of an IPC), so we
    //  can use a direct query to the CSN using the correlation with the ITEM attribute of the CSN with the hotspotId.
    //  Note that this solution is not sure when we can use the HOTSPOT, that is why we do this onsly if we can't.
    if (!hotspotEl) {
      return this.store.ipcXML.querySelector(`CSN[ITEM='${hotspotId}']`);
    }

    // SPEC : If it exists, the ID attribute of the HOTSPOT is the same as the XREFID attribute of the XREF element
    //  that is son of the targeted CSN, so we can query i preciselly
    const id = hotspotEl.getAttribute("ID");
    return this.store.ipcXML.evaluate(
      `//XREF[@XREFID='${id}']/..`,
      this.store.ipcXML,
      undefined,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      undefined
    ).singleNodeValue;
  }

  /**
   *  Convert indenture to a number of points or star
   *
   * @param part
   * @returns
   * @memberof IpcService
   */
  public getIndentStr(part: IpcDetail): string {
    const identCar = !part.attachingPart ? "•••••••••••••" : "*************";
    return identCar.substr(0, part.indenture - 1);
  }

  /**
   * Function to get the table "Equivalent part number"
   */
  public getEquivalentPartNumber(partElement: Element, mpn: string): EPN[] {
    const partRefs = partElement.querySelectorAll(`partRef[partNumberValue='${mpn}']`);
    const epns: EPN[] = [];
    for (const partRef of Array.from(partRefs)) {
      const epnList = partRef.parentElement.querySelectorAll("partSegment partRef");
      for (const ref of Array.from(epnList)) {
        const manufacturerCodeValue = ref.getAttribute("manufacturerCodeValue");
        const partNumberValue = ref.getAttribute("partNumberValue");
        if (!epns.some(el => el.mpn === partNumberValue && el.mfc === manufacturerCodeValue)) {
          epns.push({
            mpn: partNumberValue,
            mfc: manufacturerCodeValue
            // NEXT UPGRADE: Equivalent Part Number with applicability inline
            // applicabilityMD5: applicabilityMD5
          });
        }
      }
    }
    return epns;
  }

  /**
   * Find the parameterIdent from the selectedParts
   * This function is only used for S1000D aircraft
   *
   * @returns
   */
  public findHotspotId(): string {
    return xpath
      .select(
        "string(//multimediaObject[@infoEntityIdent='" +
          this.store.currentMediaId +
          "']/parameter[@parameterName='HOT" +
          this.store.selectedParts +
          "']/@parameterIdent)",
        this.store.duObject.xml
      )
      .toString();
  }

  public compareHotspot(h1: string, h2: string): boolean {
    if (!h1 || !h2) {
      return false;
    }
    return h1.replace(/^0+/g, "") === h2.replace(/^0+/g, "");
  }

  /**
   * Get value with querySelectorAll
   *
   * @param currentIsn
   * @param selector
   */
  private getValues(currentIsn, selector: string) {
    return (
      currentIsn.querySelector(selector) &&
      Array.from(currentIsn.querySelectorAll(selector).values())
        .map((epn: Element) => epn.innerHTML)
        .join(", ")
    );
  }

  /**
   * Create card on IPC TOC Node
   *
   * @param key
   * @param details
   * @return currentNode
   */
  private createDataForIpcTocNode(key: string, details: IpcDetail): IpcTocData {
    // init index for mat tab group
    this.k = 0;
    // create a new node
    // only if no node is passed and the current node isn't a version of the previous one
    const currentNode = {
      key,
      indenture: details.indenture,
      hotspot: details.hotspotId,
      version: [],
      children: [],
      attachingParts: [],
      fittingVariants: []
    };
    // Each node that is not a attachingpart is potentially a parentForAttachingPart
    if (!details.attachingPart) {
      this.parentForAttachingPart = currentNode;
    }
    // Each node that is not a fittingVariant is potentially a parentForFittingPart
    if (!details.fittingVariant) {
      this.parentForFittingPart = currentNode;
    }
    // Each node that is not a attachingpart and not fittingVariant is potentially a parentForIndenture
    if (!details.attachingPart && !details.fittingVariant) {
      this.parentForIndenture[currentNode.indenture] = currentNode;
    }
    if (details.indenture === 1) {
      // Only push into a node if its level is 1
      this.items.push(currentNode);
    } else {
      // if node is a attachingPart we attach it to the parentForAttachingPart node
      if (details.attachingPart) {
        this.parentForAttachingPart.attachingParts.push(currentNode);
      }
      if (details.fittingVariant) {
        this.parentForFittingPart.fittingVariants.push(currentNode);
      }
      if (!details.attachingPart && !details.fittingVariant) {
        // SPEC: We can have holes in parentForIndenture.
        // So starting from currentNode.indenture - 1 we decrement indenture until we find the first existing parent node.
        let i = currentNode.indenture - 1;
        while (!this.parentForIndenture[i] && i > 0) {
          i--;
        }
        this.parentForIndenture[i]?.children.push(currentNode);
      }
    }
    return currentNode;
  }

  private retrieveLocalManufact(node: Element): string {
    const localManufact: Element = node.querySelector("SMF[VALUE='M']>MFM");
    if (localManufact) {
      return this.translate.instant("ipcDetails.labels.localManufM", {
        mfm: localManufact.innerHTML
      });
    }
    // In case of not handled VALUE or no SMF
    const mfm = node.querySelector("MFM");
    return mfm && mfm.innerHTML;
  }

  private getApplicableVersions(): string {
    const applicList = this.store.ipcXML.querySelectorAll("WP6ApplicList>applic");
    const versions = Array.from(applicList).map((applic: Element) =>
      applic.getAttribute("version")
    );
    return versions.join(", ");
  }
}
