/**
 * TODO:
 * when we update to angular 18, we won't need validators anymore
 * https://material.angular.io/components/autocomplete/examples#autocomplete-require-selection
 */
import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from "@angular/core";
import { ApplicabilityService, ViewerMessageService, Store } from "@viewer/core";
import { FormControl, ValidatorFn } from "@angular/forms";
import { Observable, startWith, map } from "rxjs";
import { action } from "mobx-angular";
import { MatDialogRef } from "@angular/material/dialog";
import { UserCriterias } from "@viewer/core/applicability/applicability.service";
import { TranslateService } from "@ngx-translate/core";
import { makeObservable, runInAction } from "mobx";
import { VersionMappingPipe } from "@viewer/core/pipe/version-mapping.pipe";
import { ApplicabilityFleet, SerialNumber } from "@orion2/models/applicability.models";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { MatOption } from "@angular/material/core";

@Component({
  selector: "o-applic-modal",
  templateUrl: "./applic-modal.component.html",
  styleUrls: ["./applic-modal.component.scss"]
})
export class ApplicModalComponent implements OnInit, AfterViewInit {
  @ViewChild("versionAutoComplete")
  private versionAutoComplete: MatAutocomplete;
  @ViewChild("serialNumberAutoComplete")
  private serialNumberAutoComplete: MatAutocomplete;

  public versions: string[];
  public serialNumbers: SerialNumber[];

  public selectedModel: string;

  public isLoading = false;

  public showNotApplicable = true;
  public isVersionSelectionDisabled = false;
  public isFilterOn: boolean;

  public versionController = new FormControl("");
  public serialNumberController = new FormControl("");

  public filteredVersions: Observable<string[]>;
  public filteredSerialNumbers: Observable<SerialNumber[]>;

  public validVersion = true;
  public validSerialNumber = true;

  private versionMappingPipe: VersionMappingPipe;

  private versionMapping = new Map<string, string>();

  constructor(
    public store: Store,
    private applicabilityService: ApplicabilityService,
    private messageService: ViewerMessageService,
    private dialog: MatDialogRef<ApplicModalComponent>,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    this.versionMappingPipe = new VersionMappingPipe(store);
    makeObservable(this, {
      toggleShowNotApplicable: action
    });
  }

  public ngOnInit(): void {
    this.versionController.setValidators([this.versionValidator()]);
    this.filteredVersions = this.versionController.valueChanges.pipe(
      startWith(""),
      map((search: string) => this.searchVersion(search))
    );

    this.serialNumberController.setValidators([this.serialNumberValidator()]);
    this.filteredSerialNumbers = this.serialNumberController.valueChanges.pipe(
      startWith(""),
      map((search: string) => this.searchSerialNumber(search))
    );
  }

  ngAfterViewInit(): void {
    this.initFilter();
    this.setSelectedSerialNumber();
    this.setSelectedVersion();
  }

  public initFilter(): void {
    const fleet: ApplicabilityFleet = this.applicabilityService.initFleet();

    this.serialNumbers = fleet.serialno || [];
    this.versions = fleet.version || [];
    this.versionMapping = new Map<string, string>(
      this.versions.map((version: string) => [
        version,
        this.versionMappingPipe.transform(version, this.selectedModel)
      ])
    );

    this.isFilterOn = !!this.applicabilityService.userSelectedCriterias.filterOn;
    this.selectedModel = this.applicabilityService.userSelectedCriterias.model;
    if (fleet.model?.length === 1) {
      this.selectedModel = fleet.model[0];
    }

    this.serialNumberController.setValue(this.applicabilityService.userSelectedCriterias.serialno);

    if (this.versions.length === 1) {
      this.versionController.setValue(fleet.version[0]);
      this.isVersionSelectionDisabled = true;
    } else {
      this.versionController.setValue(this.applicabilityService.userSelectedCriterias.version);
    }

    this.showNotApplicable = !!this.applicabilityService.userSelectedCriterias.showNotApplicable;
    runInAction(() => {
      this.store.showNotApplicable = this.showNotApplicable;
    });
    this.cd.detectChanges();
  }

  /**
   * In the serial numbers select we want to display only the sn which start with
   * the string the user has typed
   *
   * @param testedSerialno
   */
  public filterSerialno(testedSerialno: string, selectedVersion?: string): SerialNumber[] {
    return selectedVersion
      ? this.serialNumbers.filter(
          (serialno: SerialNumber) =>
            serialno.label.indexOf(testedSerialno) >= 0 &&
            serialno.version.indexOf(selectedVersion) >= 0
        )
      : this.serialNumbers.filter(
          (serialno: SerialNumber) => serialno.label.indexOf(testedSerialno) >= 0
        );
  }

  /**
   * When the user selects a serial we can deduce the version from it.
   * We do it thanks to the fleet.
   */
  public setVersionFromSerial(): void {
    const version = this.getVersionFromSerialNumber(this.serialNumberController.value);
    if (version) {
      this.versionController.setValue(version);
      this.serialNumberController.updateValueAndValidity();
      this.setSelectedVersion();
      this.setSelectedSerialNumber();
    }
  }

  public close(): void {
    this.dialog.close();
  }

  /**
   * we want to reset the serial number
   * in case the user selects a new version and previous serial number is not set in this version
   */
  public resetSerialNumber(): void {
    const serialNumberVersion = this.getVersionFromSerialNumber(this.serialNumberController.value);
    const versionSelected = this.versionController.value;
    if (serialNumberVersion !== versionSelected) {
      this.serialNumberAutoComplete.options.forEach(option => option.deselect());
      this.serialNumberController.setValue("");
    }
    this.setSelectedVersion();
  }

  /**
   * Launch the process to find all the applicable md5 in function of the criterias selection
   */
  public filter(): void {
    const criterias: UserCriterias = {
      filterOn: this.isFilterOn,
      model: this.selectedModel,
      version: this.versionController.value,
      serialno: this.serialNumberController.value,
      showNotApplicable: this.showNotApplicable
    };

    try {
      this.isLoading = true;

      if (!criterias.version && !criterias.serialno) {
        // We want to keep the current filterOn value
        this.applicabilityService.reset(criterias.filterOn);
      } else {
        this.applicabilityService.filter(criterias);
      }
      this.isLoading = false;
      this.dialog.close();
      if (this.isFilterOn) {
        this.messageService.success(
          `${this.translate.instant("applicability.message.applied")} ${
            this.versionController.value ? this.selectedModel : "All"
          } ${this.versionController.value || ""} ${this.serialNumberController.value}`
        );
      } else {
        this.messageService.warning(this.translate.instant("applicability.message.disabled"));
      }
    } catch (e) {
      this.messageService.error(this.translate.instant("applicability.message.error"));
      console.error(e);
    }
  }

  /**
   * The user can choose if we want to see the unapplicable content with particular style
   * or simply hide it
   */
  public toggleShowNotApplicable() {
    this.store.showNotApplicable = this.showNotApplicable;
  }

  private getVersionFromSerialNumber(serialNumber: string): string {
    return this.serialNumbers.find((sn: SerialNumber) => sn.label === serialNumber)?.version;
  }

  private searchVersion(search: string): string[] {
    return [...this.versionMapping.entries()]
      .filter(([_, mappedVersion]) => mappedVersion.includes(search ?? ""))
      .map(([version, _]) => version);
  }

  /**
   * Checks if the formControl for the version is correctly filled.
   * @returns if the form is valid.
   */
  private versionValidator(): ValidatorFn {
    return (control: FormControl) => {
      const value = control.value;
      if ([...this.versionMapping.keys()].includes(value) || !value) {
        this.validVersion = true;
        return null;
      }
      this.validVersion = false;
      return { invalidOption: true };
    };
  }

  private searchSerialNumber(search: string): SerialNumber[] {
    if (search) {
      return this.filterSerialno(search, this.versionController.value);
    }
    return this.versionController.value
      ? this.applicabilityService.getAllSNFromVersion(this.versionController.value)
      : this.serialNumbers;
  }

  /**
   * Checks if the formControl for the serial number is correctly filled.
   * @returns if the form is valid.
   */
  private serialNumberValidator(): ValidatorFn {
    return (control: FormControl) => {
      const value = control.value;

      const filtered = this.versionController.value
        ? this.serialNumbers.filter(
            (serialno: SerialNumber) => serialno.version.indexOf(this.versionController.value) >= 0
          )
        : this.serialNumbers;

      if (!value || filtered.some((serialNumber: SerialNumber) => serialNumber.label === value)) {
        this.validSerialNumber = true;
        return null;
      }
      this.validSerialNumber = false;
      return { invalidOption: true };
    };
  }

  // Highlight the selected option in the version autocomplete.
  private setSelectedVersion(): void {
    this.versionAutoComplete.options
      .find((option: MatOption) => option.value === this.versionController.value)
      ?.select();
    this.cd.detectChanges();
  }

  // Highlight the selected option in the serial number autocomplete.
  private setSelectedSerialNumber(): void {
    this.serialNumberAutoComplete.options
      .find((option: MatOption) => option.value === this.serialNumberController.value)
      ?.select();
    this.cd.detectChanges();
  }
}
